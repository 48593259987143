import './contact.module.css';

function ContactPage() {
  return (
    <div>
        <p>Follow us</p>
    </div>
  );
}

export default ContactPage;
