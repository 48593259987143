import './landingpage.module.css';
import LandingScreen from '../../components/landingpage/landingscreen/landingscreen'

function LandingPage() {
  return (
    <div>
        <LandingScreen></LandingScreen>
    </div>
  );
}

export default LandingPage;
