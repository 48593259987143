import './blog.module.css';
import Bloghome from '../../components/blog/bloghome/bloghome'

function Blog() {
  return (
    <div>
        <Bloghome/>
    </div>
  );
}

export default Blog;
